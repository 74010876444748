import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import SpinningCircles from "react-loading-icons/dist/esm/components/spinning-circles";

import formClasses from "../forms/Forms.module.css";
import styleClasses from "./RegistrationForm.module.css";

import CheckoutContext from "../../store/CheckoutContext";
import EventContext from "../../store/EventContext";
import UserInfoContext from "../../store/UserInfoContext";

import Button from "../Button";
import RegisterButton from "./RegisterButton";

const Certification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [didComplete, setDidComplete] = useState(false);

  const [certID, setCertID] = useState("");
  const [payMethod, setPayMethod] = useState(null);

  const userCtx = useContext(UserInfoContext);
  const eventCtx = useContext(EventContext);
  const checkoutCtx = useContext(CheckoutContext);

  const navigate = useNavigate();

  const [selectedClass, setSelectedClass] = useState("Level 1 Certification");
  const selectedClassDates = Object.entries(
    eventCtx.selectedEvent.event_date_obj
  ).filter((arr) => arr[0] === selectedClass)[0][1];
  const selectedClassPrices = Object.entries(
    eventCtx.selectedEvent.price_obj
  ).filter((arr) => arr[0] === selectedClass)[0][1];

  const { format } = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [isMember, setIsMember] = useState();

  return (
    <div className={styleClasses.page}>
      <h4 className={styleClasses.disclaimer}>
        Each person is responsible for his/her own hotel reservations.
      </h4>
      <section
        className={` ${styleClasses.section} ${styleClasses.borderOrange}`}
      >
        <h2>Certification Class</h2>
        <select
          name="certification"
          id="certification"
          onChange={(e) => setSelectedClass(e.target.value)}
        >
          <option value="Level 1 Certification">Level 1 Certification</option>
          <option value="Level 1 Recertification">
            Level 1 Recertification
          </option>
          <option value="Level 2 Certification">Level 2 Certification</option>
          <option value="Level 2 Recertification">
            Level 2 Recertification
          </option>
          <option value="CMDT Certification">CMDT Certification</option>
          <option value="CMDT Recertification">CMDT Recertification</option>
          <option value="Master Certification">Master Certification</option>
        </select>
        <br />
        <br />
        <div>
          {Object.entries(selectedClassDates).map(([key, value]) => {
            return (
              <p key={`${key}${value}`} style={{ textAlign: "center" }}>
                {key} - {value}
              </p>
            );
          })}
          <br />
          {selectedClass !== "Level 1 Certification" && (
            <div>
              <input
                style={{
                  height: "2em",
                  borderRadius: "5px",
                  border: "none",
                  paddingLeft: "10px",
                }}
                placeholder="Cert ID (optional)"
                onChange={(e) => setCertID(e.target.value)}
              />
            </div>
          )}
          <br />
          <h2>Pricing:</h2>
          <p style={{ textAlign: "center" }}>
            <i>Please select your membership status</i>
          </p>
          <span className={`${formClasses.checkbox}`}>
            <input
              name="isMember"
              type="radio"
              value={"Member"}
              onChange={(e) => {
                setIsMember(true);
              }}
            />
            <label htmlFor="isMember">{`Member - ${format(selectedClassPrices["Member"])}`}</label>
          </span>
          <span className={`${formClasses.checkbox}`}>
            <input
              name="isMember"
              type="radio"
              value={"Non-Member"}
              onChange={(e) => {
                setIsMember(false);
              }}
            />
            <label htmlFor="isMember">{`Non-Member - ${format(selectedClassPrices["Non-Member"])}`}</label>
          </span>
        </div>
      </section>

      <br />
      <h3>For more information:</h3>
      <a
        href={`https://yplstzafkrlvgcnssbfi.supabase.co/storage/v1/object/public/event_storage/${eventCtx.selectedEvent.event_attachment_filename}`}
        target="about_blank"
      >
        Download the PDF registration packet
      </a>
      <br />
      <br />
      <br />
      {isLoading ? (
        <SpinningCircles stroke="#ffffff30" fill="#FFF" speed="1.0" />
      ) : (
        submitMessage
      )}
      <br />
      <br />
      {!didComplete && (
        <RegisterButton
          setIsLoading={setIsLoading}
          setSubmitMessage={setSubmitMessage}
          didComplete={setDidComplete}
          registrantData={{
            eventID: eventCtx.selectedEvent.id,
            event: eventCtx.selectedEvent,
            company: userCtx.company,
            firstName: userCtx.firstName,
            lastName: userCtx.lastName,
            address: `${userCtx.street} ${userCtx.city} ${userCtx.state} ${userCtx.zip}`,
            email: userCtx.email,
            phone: userCtx.phone,
            customInputs: {
              class: selectedClass,
              memberStatus: isMember ? "Member" : "Non-Member",
              certID: certID ? certID : "N/A",
            },
          }}
        />
      )}
      {didComplete && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "5em",
          }}
        >
          <h2>Pay Method:</h2>
          <div
            className={styleClasses.checkbox}
            style={{ marginBottom: "2em" }}
          >
            <input
              type="radio"
              name="pay-method"
              value="Check"
              onChange={() => setPayMethod("Check")}
            />
            <label htmlFor="pay-method">Check</label>
            <input
              type="radio"
              name="pay-method"
              value="Card"
              onChange={() => setPayMethod("Card")}
            />
            <label htmlFor="pay-method">Card</label>
          </div>
          {payMethod === "Check" && (
            <div style={{ marginBottom: "5em" }}>
              <h3>Please Mail Check to:</h3>
              <p style={{ margin: "0", textAlign: "center" }}>
                Mississippi Asphalt Pavement Association
              </p>
              <p style={{ margin: "0", textAlign: "center" }}>
                711 N. Presidents St.
              </p>
              <p style={{ margin: "0", textAlign: "center" }}>
                Jackson, MS 39202
              </p>
            </div>
          )}
          {payMethod && (
            <Button
              backgroundColor={"#FFA559"}
              shadowColor={"#454545"}
              title={payMethod === "Check" ? "Okay" : "Proceed to Checkout"}
              onClick={() => {
                if (payMethod === "Check") {
                  navigate("/");
                } else {
                  checkoutCtx.addItem({
                    itemName: `${selectedClass} (${
                      isMember ? "Member" : "Non-Member"
                    })`,
                    itemPrice: isMember
                      ? selectedClassPrices["Member"]
                      : selectedClassPrices["Non-Member"],
                  });
                  navigate("/checkout");
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Certification;
