import { useNavigate } from "react-router-dom";

import { supaBaseRefId } from "../util/backendUtilities";

import PageTitleBar from "../components/PageTitleBar";
import Button from "../components/Button";

import classImg from "./assets/certs-photo.jpg";

import classes from "./CertsTraining.module.css";

const CertsTraining = () => {
  const navigate = useNavigate();

  const hmaGeneralPDF = `https://${supaBaseRefId}.supabase.co/storage/v1/object/public/certification_pdfs/hma_general.pdf`;
  const fieldManualPDF = `https://${supaBaseRefId}.supabase.co/storage/v1/object/public/certification_pdfs/field_manual.pdf`;
  const certListPDF = `https://${supaBaseRefId}.supabase.co/storage/v1/object/public/certification_pdfs/cert_list.pdf`;
  const holmesMap = `https://${supaBaseRefId}.supabase.co/storage/v1/object/public/certification_pdfs/Holmes-map.pdf`;

  return (
    <div>
      <PageTitleBar title="CERTIFICATIONS AND TRAINING" />
      <div className={`${classes.description} ${classes.bgLinear}`}>
        <h1>HMA TECHNICIAN CERTIFICATION CLASSES</h1>
        <br />
        <h1>Level I, II, & CMDT</h1>
        <br />
        <p>
          The Mississippi Asphalt Pavement Association, Inc. (MAPA) offers a
          program of education and certification for practicing Asphalt Pavement
          Technicians. The goals of this program are to provide a group of
          experienced technicians to design and monitor asphalt mixtures. The
          intent of this program is to improve the quality and performance of
          hot mixed asphalt pavements through knowledge and understanding of the
          product. The program will provide the training and certification for
          three levels of technicians. These levels include CAT-I Testing
          Technician, CAT-II Quality Management Technician, and CMDT Mixture
          Design Technician. ​
        </p>
        <br />
        <div className={classes.centerAlign}>
          <Button
            backgroundColor={"#FFA559"}
            shadowColor={"#454545"}
            title={"Get Registered"}
            onClick={() => {
              navigate("/events");
              window.scrollTo(0, 0);
            }}
          />
        </div>
        <br />
        <h1>Certified Asphalt Technician – I (CAT-I)</h1>
        <br />
        <p>
          The CAT-I will be responsible for the daily sampling, testing, data
          charting, and process monitoring at the HMA plant. Prospective
          candidates will be required to have basic math skills, the ability to
          use a calculator and meet one of the following requirements:
        </p>
        <ol>
          <li>
            A minimum of three months of hands on experience under the
            supervision of a current CAT-1.
          </li>
          <li>
            Successful completion of an industry conducted class on the
            Introduction to Basic Asphalt Technology.
          </li>
          <li>
            A minimum of two years of current experience in the HMA industry
            doing Quality Control testing.
          </li>
        </ol>
        <p>
          The training will consist of a 2-4-day program of class and laboratory
          sessions. The laboratory phase will provide hands on experience for
          the required QMP program. Certification will be based on the
          successful completion of a written examination and demonstrated
          competence in the required field sampling and testing program.
        </p>
      </div>
      <div className={`${classes.bgOrange} ${classes.description}`}>
        <h1>Certified Asphalt Technician – II (CAT-II)</h1>
        <br />
        <p>
          The CAT-II will be responsible for the successful operations of the
          quality control program at the HMA plant and the necessary adjustments
          to the process to maintain the mixture within the required control
          limits. Prospective candidates shall have successfully completed the
          CAT I program. Certification will be based on the successful
          completion of a written examination Certified Asphalt Technician – II
          (CAT-II)
        </p>
      </div>
      <div className={`${classes.bgLinear} ${classes.description}`}>
        <h1>Certified Mixture Design Technician(CMDT)</h1>
        <br />
        <p>
          The CMDT will be responsible for testing according to MDOT design
          procedures for the development of a JMF for HMA mixtures. Prospective
          candidates will be required to have basic math skills, the ability to
          use a calculator and meet one of the following experience
          requirements:
        </p>
        <ol>
          <li>A minimum of three months experience as a CAT-I.</li>
          <li>
            A minimum of three months experience under the supervision of a
            current CMDT.
          </li>
          <li>
            A minimum of two years of current experience in the HMA industry
            doing Quality Control or Mixture Design testing.
          </li>
        </ol>
        <p>
          The training will consist of a 5-day program of class and laboratory
          sessions. Certification will be based on the successful completion of
          a written examination and the submittal of a mixture design conducted
          according to MDOT design procedures.
        </p>
      </div>
      <div className={`${classes.bgOrange} ${classes.description}`}>
        <h1>Master Mixture Design Technician (MMDT)</h1>
        <br />
        <p>Must meet at least one of the following two requirements below</p>
        <ol>
          <li>
            Must have a minimum of 10 years as a Level 3 Technician – must pass
            the recertification test twice as a milestone accomplishment
          </li>
          <li>
            Must have a minimum of 5 years as Level 3 Technician and MS PE
            License
          </li>
        </ol>
        <p>
          After obtaining the Master designation you must attend 2 MQAC’s within
          the renewal period for continuing education to stay up to date with
          mix design changes for MDOT
        </p>
      </div>
      <div className={classes.description} style={{ color: "white" }}>
        <h1>Recertification</h1>
        <br />
        <p>
          Certification shall be valid at all levels for a period of five years.
          Recertification for active technicians (those technicians working a
          minimum of 500 hours at the certification level) shall consist of
          attendance at a one-day review class. Recertification for non-active
          technicians (those, technicians working less than 500 hours at the
          certification level) shall · consist of the technician retaking the
          required examination and demonstrating the required for the
          certification level. Recertification will only be required for the
          highest level of certification obtained.
        </p>
        <br />
        <p>
          <strong>Note: </strong>
          <i>
            People attending for re-certification examinations are welcome to
            attend the review portion of that class the week before taking the
            exam.
          </i>
        </p>
        <div className={classes.centerAlign}>
          <Button
            backgroundColor={"#FFA559"}
            shadowColor={"#454545"}
            title={"Get Registered"}
            onClick={() => {
              navigate("/events");
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
      <br />
      <br />
      <div className={classes.info}>
        <div className={classes.description}>
          <h1>INSTRUCTOR</h1>
          <p>Randy Ahlrich, Ph.D., P.E.Burns Cooley </p>
          <p>Dennis, Inc. 601-856-2332</p>
          <br />
          <h1>LOCATION</h1>
          <p>Pat McGowan Workforce Center Holmes Community College</p>
          <p>Ridgeland, MS 39157 </p>
          <br />
          <a href={holmesMap} target="about_blank">
            Download Map
          </a>
        </div>
        <div>
          <img src={classImg} alt="" />
        </div>
      </div>
      <div className={`${classes.links} ${classes.bgOrange}`}>
        <Button
          backgroundColor={"#454545"}
          shadowColor={"#454545"}
          title={"HMA Manual"}
          onClick={() => window.open(hmaGeneralPDF)}
        />
        <Button
          backgroundColor={"#454545"}
          shadowColor={"#454545"}
          title={"Asphalt Field Manual"}
          onClick={() => window.open(fieldManualPDF)}
        />
        <Button
          backgroundColor={"#454545"}
          shadowColor={"#454545"}
          title={"List of certified students"}
          onClick={() => window.open(certListPDF)}
        />
      </div>
    </div>
  );
};

export default CertsTraining;

//* This section was removed from the top of the page because this section was not needed.
//* It is commented out in case it needs to be added back in.
//const stormTraining = `https://${supaBaseRefId}.supabase.co/storage/v1/object/public/certification_pdfs/storm_training.pdf`;
/* <div className={`${classes.description} ${classes.bgLinear}`}>
        <h1>MDOT, MRBA, AND MAPA STORM WATER TRAINING</h1>
        <br />
        <h1>Storm Water Training</h1>
        <p>
          Thompson Engineering will be performing the Storm Water Training as
          specific locations throughout the state in January, February, March,
          April, May and June of 2023. For those persons that were certified in
          2018, you will need to become re-certified because the certification
          is good for 5 years.
        </p>
      </div>
      <div
        className={`${classes.center} ${classes.bgOrange}`}
        style={{ padding: "50px" }}
      >
        <Button
          backgroundColor={"#454545"}
          shadowColor={"#454545"}
          title={"View Schedule"}
          onClick={() => window.open(stormTraining)}
        />
      </div> */
