import { useContext, useEffect, useState } from "react";

import { IconContext } from "react-icons";
import { IoCloseCircleOutline } from "react-icons/io5";

import classes from "./Checkout.module.css";

import PageTitleBar from "../components/PageTitleBar";
import StripeContainer from "../components/stripe/StripeContainer";
import Button from "../components/Button";
import CheckoutContext from "../store/CheckoutContext";

import ReCaptcha, {
  useVerifyRecaptcha,
} from "../components/grecaptcha/ReCaptcha";
import Form from "../components/forms/Form";
import NameInputs from "../components/forms/NameInputs";
import AddressInputs from "../components/forms/AddressInputs";
import ContactInputs from "../components/forms/ContactInputs";
import UserInfoContext from "../store/UserInfoContext";
import {
  formIsValid,
  isInvalidInput,
} from "../components/forms/formValidation";

const Checkout = () => {
  const checkoutCtx = useContext(CheckoutContext);

  const [orderItems, setOrderItems] = useState([]);

  const [didProceedToCheckout, setDidProceedToCheckout] = useState(false);

  const [isPayingInv, setIsPayingInv] = useState(false);
  const [invoiceNum, setInvoiceNum] = useState("");
  const [invoiceAmt, setInvoiceAmt] = useState("");

  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaIsVerified = useVerifyRecaptcha(recaptchaToken);

  useEffect(() => {
    setOrderItems(checkoutCtx.items);
  }, [checkoutCtx.items]);

  const submitHandler = () => {
    if (recaptchaIsVerified) {
      //Array that checks for invalid user info
      const inputsInvalidityArr = [
        isInvalidInput(userCtx.firstName, "text"),
        isInvalidInput(userCtx.lastName, "text"),
        isInvalidInput(userCtx.street, "text"),
        isInvalidInput(userCtx.city, "text"),
        userCtx.state === "",
        isInvalidInput(userCtx.zip, "zip"),
        isInvalidInput(userCtx.email, "email"),
        isInvalidInput(userCtx.phone, "phone"),
        isInvalidInput(userCtx.company, "text"),
      ];
      if (formIsValid(inputsInvalidityArr)) {
        setDidProceedToCheckout(true);
      } else {
        alert("Please fill out all information correctly and resubmit");
      }
    } else {
      alert("Please complete the reCAPTCHA checkbox");
    }
  };

  const userCtx = useContext(UserInfoContext);
  const customerInfo = {
    firstName: userCtx.firstName,
    lastName: userCtx.lastName,
    street: userCtx.street,
    city: userCtx.city,
    zip: userCtx.zip,
    state: userCtx.state,
    email: userCtx.email,
    phone: userCtx.phone,
    orderItems,
  };

  const calcSubTotal = () => {
    const itemPrices = [];
    orderItems.forEach((item) => {
      const price = +item.itemPrice;
      itemPrices.push(price);
    });
    return itemPrices.reduce((acc, curr) => acc + curr, 0);
  };

  const threePercentOfTotal = () => {
    return calcSubTotal() * 0.03;
  };

  const { format } = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const enterInvoice = () => {
    console.log(invoiceNum, invoiceAmt);
    checkoutCtx.addItem({
      itemName: `Invoice# ${invoiceNum}`,
      itemPrice: invoiceAmt,
    });
    setInvoiceNum("");
    setInvoiceAmt("");
    setIsPayingInv(false);
  };

  return (
    <div>
      <PageTitleBar title={"CHECKOUT"} />
      <div>
        {!isPayingInv && (
          <div className={classes.centerHorizontal}>
            <Button
              fontColor="#454545"
              backgroundColor={"#FFF"}
              shadowColor={"#454545"}
              title={"Paying an Invoice?"}
              onClick={() => setIsPayingInv(true)}
            />
          </div>
        )}
        {isPayingInv && (
          <div className={`${classes.enterInv} ${classes.pageAlign}`}>
            <div className={classes.closeBtn}>
              <IconContext.Provider
                value={{ style: { color: "#454545", marginLeft: "1em" } }}
              >
                <IoCloseCircleOutline onClick={() => setIsPayingInv(false)} />
              </IconContext.Provider>
            </div>
            <label htmlFor="invoice">Enter Invoice #</label>
            <input
              type="text"
              id="invoice"
              value={invoiceNum}
              onChange={(e) => setInvoiceNum(e.target.value)}
            />
            <label htmlFor="amount">Enter Amount</label>
            <input
              type="text"
              id="amount"
              value={invoiceAmt}
              onChange={(e) => {
                setInvoiceAmt(e.target.value);
              }}
            />
            <br />
            <br />
            <Button
              fontColor="#fff"
              backgroundColor={"#454545"}
              shadowColor={"#454545"}
              title={"Enter"}
              onClick={() => enterInvoice()}
            />
          </div>
        )}
        <br />
        <br />
        <div className={`${classes.orderSummary}`}>
          {orderItems.length !== 0 && (
            <>
              <h1>Order Summary:</h1>
              <table className={`${classes.summaryTable}`}>
                <tbody>
                  <tr>
                    <th>
                      <h2>Item</h2>
                    </th>
                    <th>
                      <h2>Price</h2>
                    </th>
                  </tr>
                  {orderItems.map((item, index) => (
                    <tr key={index}>
                      <th>
                        <p style={{ margin: "2px" }}>{item.itemName}</p>
                      </th>
                      <th>
                        <p style={{ margin: "2px" }}>
                          {format(+item.itemPrice)}
                        </p>
                      </th>
                      <th>
                        <IconContext.Provider
                          value={{ style: { color: "#ffa559" } }}
                        >
                          <IoCloseCircleOutline
                            onClick={() => checkoutCtx.removeItem(index)}
                          />
                        </IconContext.Provider>
                      </th>
                    </tr>
                  ))}
                  <tr>
                    <th>
                      <p style={{ marginBottom: "2px" }}>3% fee</p>
                    </th>
                    <th>
                      <p style={{ marginBottom: "2px" }}>
                        {format(threePercentOfTotal())}
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <h2 style={{ marginBlock: "6px", color: "#ffa559" }}>
                        Total:
                      </h2>
                    </th>
                    <th>
                      <h2 style={{ marginBlock: "6px", color: "#ffa559" }}>
                        {format(calcSubTotal() + threePercentOfTotal())}
                      </h2>
                    </th>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ textAlign: "left" }}>
                <i>A 3% fee is added for credit card payments</i>
              </h4>
            </>
          )}
          <br />
          <br />
        </div>
        <Form>
          <NameInputs />
          <AddressInputs />
          <ContactInputs />
        </Form>
        <br />
        <br />
        <div className={classes.pageAlign}>
          <StripeContainer
            setDidProceed={setDidProceedToCheckout}
            didProceed={didProceedToCheckout}
            customerInfo={customerInfo}
            orderTotal={calcSubTotal() + threePercentOfTotal()}
            clearCart={setOrderItems}
            reset={() => {
              userCtx.setFirstName("");
              userCtx.setLastName("");
              userCtx.setStreet("");
              userCtx.setCity("");
              userCtx.setZip("");
              userCtx.setEmail("");
              userCtx.setPhone("");
              userCtx.setState("");
            }}
          />
        </div>
        <div style={{ marginBlock: "75px" }} />
        <div
          className={classes.centerHorizontal}
          style={{ display: `${didProceedToCheckout ? "none" : "flex"}` }}
        >
          <ReCaptcha setToken={setRecaptchaToken} />
        </div>
        <div style={{ marginBlock: "75px" }} />
        <div className={classes.centerHorizontal}>
          {!didProceedToCheckout && (
            <Button
              backgroundColor={"#FFA559"}
              shadowColor={"#454545"}
              title={"Submit"}
              onClick={() => {
                submitHandler();
              }}
            />
          )}
        </div>
        <div style={{ minHeight: "150px" }}></div>
      </div>
    </div>
  );
};

export default Checkout;
