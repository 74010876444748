import axios from "axios";
import { siteIP } from "../../util/backendUtilities";

import Button from "../Button";
import { sendEventRegEmail } from "../emailJS/EmailJS";

import { formIsValid, isInvalidInput } from "../forms/formValidation";

const RegisterButton = (props) => {
  const { registrantData, didComplete, setIsLoading, setSubmitMessage, isDisabled } = props;

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const inputsInvalidityArr = [
      isInvalidInput(registrantData.firstName, "text"),
      isInvalidInput(registrantData.lastName, "text"),
      isInvalidInput(registrantData.email, "email"),
      isInvalidInput(registrantData.phone, "phone"),
      isInvalidInput(registrantData.company, "text"),
    ];
    if (formIsValid(inputsInvalidityArr)) {
      axios
        .post(`${siteIP}/event-register`, registrantData)
        .then((res) => {
          setIsLoading(false);
          sendEventRegEmail(registrantData);
          setSubmitMessage(
            <div>
              <h2 style={{ color: "green" }}>{res.data}</h2>
              <p style={{ textAlign: "center" }}>
                A confirmation email has been sent
              </p>
            </div>
          );
          didComplete(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          setSubmitMessage(<h2 style={{ color: "red" }}>{err.response.data}</h2>);
        });
    } else {
      setIsLoading(false)
      alert ("Please fill out all information correctly and resubmit")
    }
  };

  //^ this is an example of how to loop through the custom_inputs_obj returned
  //^ from the database.
  // for (const [key, value] of Object.entries(obj.custom_inputs_obj)) {
  //   console.log(key, value);
  // }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "5em",
        }}
      >
        <Button
          disabled={isDisabled}
          backgroundColor={"#FFA559"}
          shadowColor={"#454545"}
          title={"Register"}
          onClick={submitHandler}
        />
      </div>
    </>
  );
};

export default RegisterButton;
